import { TipoFotoEnum } from '../../services-generated/models'

export const TiposFoto = [
    { id: TipoFotoEnum.RoteiroCheckin, text: 'Check-in' },
    { id: TipoFotoEnum.RoteiroCheckout, text: 'Check-out' },
    { id: TipoFotoEnum.Ruptura, text: 'Ruptura' },
    // { id: TipoFotoEnum.TarefaCMAntes, text: 'CM antes' },
    // { id: TipoFotoEnum.TarefaCMDepois, text: 'CM depois' },
    // { id: TipoFotoEnum.TarefaCaixasAbertas, text: 'Caixas abertas' },
    // { id: TipoFotoEnum.TarefaDescarte, text: 'Descarte' },
    // { id: TipoFotoEnum.TarefaEstoque, text: 'Estoque' },
    // { id: TipoFotoEnum.TarefaGaleriaFotos, text: 'Galeria de fotos' },
    // { id: TipoFotoEnum.TarefaInformarCNPJ, text: 'Informar CNPJ' },
    // { id: TipoFotoEnum.TarefaMPDV, text: 'MPDV' },
    // { id: TipoFotoEnum.TarefaPesquisaConcorrente, text: 'Pesquisa de concorrente' },
    { id: TipoFotoEnum.TarefaPesquisaForms, text: 'Tarefa personalizada' },
    // { id: TipoFotoEnum.TarefaPesquisaValidade, text: 'Pesquisa de validade' },
    // { id: TipoFotoEnum.TarefaPontoExtra, text: 'Ponto extra' },
    // { id: TipoFotoEnum.TarefaPontoPromocional, text: 'Ponto promocional' },
    // { id: TipoFotoEnum.TarefaPrecoProduto, text: 'Pesquisa de preço' },
    { id: TipoFotoEnum.TarefaReabastecimentoAntes, text: 'Antes' },
    { id: TipoFotoEnum.TarefaReabastecimentoDepois, text: 'Depois' },
    // { id: TipoFotoEnum.TarefaRegistroFotos, text: 'Registro de foto' },
    // { id: TipoFotoEnum.TarefaRegistroPresenca, text: 'Registro de presença' },
    // { id: TipoFotoEnum.TarefaShareGondola, text: 'Share de gôndola' },
    // { id: TipoFotoEnum.TarefaSugestaoPedido, text: 'Sugestão de pedido' },
    { id: TipoFotoEnum.ArquivoExpress, text: 'Fotos extras' },
    { id: TipoFotoEnum.Documentos, text: 'Documentos' }
]